var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"box"},[(_vm.dashboard)?_c('button',{staticClass:"btn btn-secondary rounded-pill mb-2",on:{"click":_vm.addContactFromTiers}},[_vm._v(_vm._s(_vm.$t('action.create_contact_from_tiers'))+" "),_c('font-awesome-icon',{staticClass:"ml-1",attrs:{"icon":['fal', 'plus']}})],1):_vm._e(),_c('CustomTable',{ref:"table",attrs:{"id_table":_vm.tableId,"items":_vm.filtredContacts,"busy":_vm.table_busy,"primaryKey":"contact_id","hide_if_empty":true,"rawColumns":_vm.rawColumnNames,"display_action_button":!_vm.horseFicheReadOnly,"checkboxes":!_vm.horseFicheReadOnly,"hrefsRoutes":_vm.config_table_hrefs,"columsAddBegin":_vm.columsAddBegin,"externSlotColumns":_vm.extern_slot_columns},on:{"update:busy":function($event){_vm.table_busy=$event}},scopedSlots:_vm._u([{key:"custom-slot-cell(hubspot)",fn:function(ref){
var data = ref.data;
return [(data.contact.contact_hubspotid)?_c('a',{attrs:{"href":'https://app.hubspot.com/contacts/8547080/contact/' + data.contact.contact_hubspotid,"target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":['cf', 'hubspot']}})],1):_vm._e()]}},{key:"custom-slot-cell(contact_mail)",fn:function(ref){
var data = ref.data;
return [_c('e-copybox',{attrs:{"classes":"row","mail":"","customtable":"","value":data.contact_mail}})]}},{key:"custom-slot-cell(phones_number)",fn:function(ref){
var data = ref.data;
return _vm._l((data.phones_number.split('<br>')),function(phone_number,index){return _c('span',{key:index},[_c('e-copybox',{attrs:{"classes":"row","phone":"","customtable":"","value":phone_number}})],1)})}},{key:"custom-slot-cell(contact_whatsapp)",fn:function(ref){
var data = ref.data;
return [(data.contact.phones.length > 0)?_c('a',{attrs:{"href":("https://wa.me/" + (data.contact.phones[0].phone_combine)),"target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'whatsapp']}})],1):_vm._e()]}}],null,true)})],1),_c('ContactForm')],1)}
var staticRenderFns = []

export { render, staticRenderFns }